export const PracticedSide = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    UNTRACKED: 'UNTRACKED'
};

const PracticedSides = [
    PracticedSide.LEFT,
    PracticedSide.RIGHT,
    PracticedSide.UNTRACKED
];

export const getPracticedSides = () => PracticedSides;
