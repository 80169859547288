import {
    useState,
    useEffect,
    useCallback,
    useRef
} from 'react';
import {
    getFileUrlById,
    getMediaThumbnailUrlById
} from '@pole-journal/aws-amplify/src/storage';
import { TimelineMediaType } from '@pole-journal/aws-amplify/src/models';

const getSourceSize = ({
    source,
    setSize
}) => {
    const img = new Image();
    img.onload = () => {
        setSize({
            width: img.width,
            height: img.height
        });
    };
    img.src = source.uri;
};

export const useMediaSource = ({
    id,
    url,
    type = TimelineMediaType.IMAGE,
    storeType,
    shouldLoadMediaSize = false,
    isPublicApi
}) => {
    const [thumbnailSource, setThumbnailSource] = useState();
    const [mediaSource, setMediaSource] = useState();
    const [thumbnailSourceSize, setThumbnailSourceSize] = useState({ width: 0, height: 0 });
    const [mediaSourceSize, setMediaSourceSize] = useState({ width: 0, height: 0 });

    const setSource = source => {
        setMediaSource(source);
        setThumbnailSource(source);
    };

    const dependenciesRef = useRef();
    dependenciesRef.current = {
        thumbnailSource,
        mediaSource,
        shouldLoadMediaSize,
        type,
        url
    };

    const refreshSources = useCallback(() => {
        const {
            thumbnailSource,
            mediaSource,
            shouldLoadMediaSize,
            type
        } = dependenciesRef.current;

        if (thumbnailSource) {
            setThumbnailSource({ ...thumbnailSource });
            shouldLoadMediaSize &&
                getSourceSize({
                    source: thumbnailSource,
                    setSize: setThumbnailSourceSize
                });
        }

        if (mediaSource) {
            setMediaSource({ ...mediaSource });
            shouldLoadMediaSize &&
                type === TimelineMediaType.IMAGE &&
                getSourceSize({
                    source: mediaSource,
                    setSize: setMediaSourceSize
                });
        }
    }, []);

    useEffect(() => {
        refreshSources();
    }, [refreshSources]);

    useEffect(() => {
        if (url) {
            const { mediaSource } = dependenciesRef.current;
            setSource({
                ...mediaSource,
                uri: url,
                type: type === TimelineMediaType.IMAGE ? 'image' : 'video'
            });
            return;
        }

        if (!id) {
            setSource(null);
            return;
        }

        getMediaThumbnailUrlById({
            id,
            storeType,
            isPublicApi
        }).then(fetchedUrl => {
            !dependenciesRef.current.url && setThumbnailSource({
                ...dependenciesRef.current.thumbnailSource,
                uri: fetchedUrl,
                type: 'image'
            });
        });

        getFileUrlById({
            id,
            storeType,
            isPublicApi
        }).then(fetchedUrl => {
            !dependenciesRef.current.url && setMediaSource({
                ...dependenciesRef.current.mediaSource,
                uri: fetchedUrl,
                type: type === TimelineMediaType.IMAGE ? 'image' : 'video'
            });
        });
    }, [
        id,
        type,
        url,
        storeType,
        isPublicApi
    ]);

    useEffect(() => {
        shouldLoadMediaSize &&
            thumbnailSource?.uri &&
            getSourceSize({
                source: thumbnailSource,
                setSize: setThumbnailSourceSize
            });
    }, [shouldLoadMediaSize, thumbnailSource]);

    useEffect(() => {
        shouldLoadMediaSize &&
            mediaSource?.uri &&
            type === TimelineMediaType.IMAGE &&
            getSourceSize({
                source: mediaSource,
                setSize: setMediaSourceSize
            });
    }, [shouldLoadMediaSize, mediaSource, type]);

    return {
        thumbnailSource,
        thumbnailSourceSize,
        mediaSource,
        mediaSourceSize,
        setMediaSource: setSource
    };
};
