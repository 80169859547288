import { useEffect } from 'react';
import { di } from 'react-magnetic-di';
import { usePrevious } from '@codexporer.io/react-hooks';
import isEqual from 'lodash/isEqual';
import { useAppAnalytics } from './use-app-analytics';

export const useSendActiveScreenEvent = ({ screenName, attributes }) => {
    di(useAppAnalytics, usePrevious);

    const { sendScreenEvent } = useAppAnalytics();
    const previousScreenName = usePrevious(screenName);
    const previousAttributes = usePrevious(attributes);

    useEffect(() => {
        if (screenName !== previousScreenName || !isEqual(attributes, previousAttributes)) {
            sendScreenEvent({ screenName, attributes });
        }
    }, [
        sendScreenEvent,
        screenName,
        previousScreenName,
        attributes,
        previousAttributes
    ]);
};
