import { useEffect } from 'react';
import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';
import { initialState, actions } from '@codexporer.io/expo-link-stores';
import filter from 'lodash/filter';
import noop from 'lodash/noop';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import { getOwner } from './user-utils';
import { graphql } from './graphql';
import { getCache } from './cache';
import { saveFile, StoreType } from '../storage';
import { TimelineMediaType } from '../models';

const localInitialState = {
    timelineMedias: [],
    isLoading: true,
    error: null,
    didInitialLoad: false
};

const moveTimelineMediaFieldsQueryFragment = `
  fragment TimelineMediaFields on TimelineMedia {
    id
    targetId
    targetUrl
    type
    order
    moveTimelineId
    _version
    _deleted
  }
`;

const getTimelineMediaByOwnerQuery = `
    ${moveTimelineMediaFieldsQueryFragment}
    query QueryTimelineMediaByOwner(
        $owner: String!
        $limit: Int
        $nextToken: String
    ) {
        result: timelineMediaByOwner(
            owner: $owner
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                ...TimelineMediaFields
            }
            nextToken
        }
    }
`;

const getSaveTimelineMediaQuery = ({ isUpdate }) => `
    ${moveTimelineMediaFieldsQueryFragment}
    mutation SaveMoveTimelineMedia
    (
        $targetId: String
        $targetUrl: String
        $type: TimelineMediaType!
        $order: Int!
        $moveTimelineId: ID!
        ${isUpdate ? `
            $id: ID!
            $_version: Int
        ` : ''}
    ) {
        result: ${isUpdate ? 'updateTimelineMedia' : 'createTimelineMedia'}(
            input: {
                targetId: $targetId
                targetUrl: $targetUrl
                type: $type
                order: $order
                moveTimelineId: $moveTimelineId
                ${isUpdate ? `
                    id: $id
                    _version: $_version
                ` : ''}
            }
        ) {
            ...TimelineMediaFields
        }
    }
`;

const deleteTimelineMediaQuery = `
    ${moveTimelineMediaFieldsQueryFragment}
    mutation DeleteTimelineMedia
    (
        $id: ID!
        $_version: Int
    ) {
        result: deleteTimelineMedia(
            input: {
                id: $id
                _version: $_version
            }
        ) {
            ...TimelineMediaFields
        }
    }
`;

const selectTimelineMediasByTimelineId = memoize(
    (timelineMedias, moveTimelineId) => moveTimelineId ? filter(
        timelineMedias,
        { moveTimelineId }
    ) : timelineMedias,
    (...args) => JSON.stringify(args)
);

const orderTimelineMediassByOrder = memoize(
    timelineMedias => sortBy(
        timelineMedias,
        ['order']
    ),
    (...args) => JSON.stringify(args)
);

const clearSelectorsCache = () => {
    selectTimelineMediasByTimelineId.cache.clear();
    orderTimelineMediassByOrder.cache.clear();
};

const setFromCache = () => async ({ setState }) => {
    if (getCache().isTimelineMediasRetrieved()) {
        return;
    }

    getCache().setTimelineMediasIsRetrieved();
    const timelineMedias = await getCache().getTimelineMedias();
    clearSelectorsCache();
    setState({ timelineMedias });
};

const setTimelineMedias = timelineMedias => ({ setState }) => {
    clearSelectorsCache();
    getCache().setTimelineMedias(timelineMedias);
    setState({ timelineMedias });
};

const resetState = () => ({ setState }) => {
    setState(localInitialState);
};

const setIsLoading = isLoading => ({ setState }) => {
    setState({ isLoading });
};

const setError = error => ({ setState }) => {
    setState({ error });
};

const fetchTimelineMedias = () => async ({ getState, dispatch }) => {
    dispatch(setFromCache());

    const { isLoading, didInitialLoad } = getState();
    if (didInitialLoad && isLoading) {
        return;
    }

    const { owner } = await getOwner();
    const timelineMedias = [];
    const fetchData = async ({ nextToken }) => {
        const result = await graphql({
            query: getTimelineMediaByOwnerQuery,
            variables: {
                owner,
                limit: 100,
                nextToken
            }
        });

        timelineMedias.push(
            ...filter(
                result.data.result?.items,
                ({ _deleted }) => _deleted !== true
            )
        );

        nextToken = result.data.result?.nextToken;
        if (nextToken) {
            await fetchData({ nextToken });
        }
    };

    try {
        dispatch(setIsLoading(true));
        dispatch(setError(null));

        await fetchData({ nextToken: null });

        dispatch(setIsLoading(false));
        dispatch(setTimelineMedias(timelineMedias));
    } catch (error) {
        dispatch(setIsLoading(false));
        dispatch(setError(error));
    }
};

const onInitialLoad = () => ({ setState, getState }) => {
    const { didInitialLoad } = getState();
    !didInitialLoad && setState({ didInitialLoad: true });
};

const saveTimelineMedia = ({
    timelineMedia,
    timelineMediaPreviousState,
    mediaUrl
}) => async ({ getState, dispatch }) => {
    const isUpdate = !!timelineMediaPreviousState;
    const savedTimelineMedia = (await graphql({
        query: getSaveTimelineMediaQuery({ isUpdate }),
        variables: { ...timelineMedia }
    })).data.result;

    const previousTargetId = timelineMediaPreviousState?.targetId;
    const { targetId } = savedTimelineMedia;
    if (previousTargetId !== targetId) {
        await (
            targetId && mediaUrl ?
                saveFile({
                    id: savedTimelineMedia.id,
                    mediaId: targetId,
                    url: mediaUrl,
                    storeType: timelineMedia.type === TimelineMediaType.IMAGE ?
                        StoreType.TIMELINE_PHOTO :
                        StoreType.TIMELINE_VIDEO
                }).catch(noop) :
                Promise.resolve()
        );
    }

    const timelineMedias = [...getState().timelineMedias];
    isUpdate && remove(timelineMedias, ({ id }) => id === savedTimelineMedia.id);
    timelineMedias.push(savedTimelineMedia);
    dispatch(setTimelineMedias(timelineMedias));
    return savedTimelineMedia;
};

const deleteTimelineMedia = ({
    timelineMedia: { id, _version }
}) => async ({ getState, dispatch }) => {
    const deletedTimelineMedia = (await graphql({
        query: deleteTimelineMediaQuery,
        variables: { id, _version }
    })).data.result;

    const timelineMedias = [...getState().timelineMedias];
    remove(timelineMedias, ({ id }) => id === deletedTimelineMedia.id);
    dispatch(setTimelineMedias(timelineMedias));
    return deletedTimelineMedia;
};

const Store = createStore({
    initialState: {
        ...initialState,
        ...localInitialState
    },
    actions: {
        ...actions,
        resetState,
        fetchTimelineMedias,
        onInitialLoad,
        saveTimelineMedia,
        deleteTimelineMedia
    },
    name: 'TimelineMedias'
});

const useTimelineMediasState = createStateHook(
    Store,
    {
        selector: (
            {
                timelineMedias,
                isLoading,
                error,
                didInitialLoad
            },
            { moveTimelineId }
        ) => ({
            timelineMedias: orderTimelineMediassByOrder(
                selectTimelineMediasByTimelineId(
                    timelineMedias,
                    moveTimelineId
                )
            ),
            isLoading,
            error,
            didInitialLoad
        })
    }
);

export const useTimelineMediasActions = createActionsHook(Store);

export const useTimelineMedias = ({
    moveTimelineId,
    canFetch
} = {}) => {
    const { fetchTimelineMedias, onInitialLoad } = useTimelineMediasActions();
    const {
        timelineMedias,
        isLoading,
        error,
        didInitialLoad
    } = useTimelineMediasState({ moveTimelineId });

    useEffect(() => {
        if (canFetch !== false && !didInitialLoad) {
            fetchTimelineMedias();
            onInitialLoad();
        }
    }, [
        canFetch,
        didInitialLoad,
        fetchTimelineMedias,
        onInitialLoad
    ]);

    return {
        timelineMedias,
        isLoading,
        error
    };
};

export const useMoveTimelineUploadedMediasCount = () => {
    const {
        timelineMedias,
        isLoading,
        error
    } = useTimelineMedias();

    if (isLoading || error || !timelineMedias) {
        return undefined;
    }

    return filter(timelineMedias, ({ targetId }) => !isEmpty(targetId)).length;
};

export const useFetchTimelineMedias = () => {
    const { fetchTimelineMedias } = useTimelineMediasActions();
    return fetchTimelineMedias;
};

export const useSaveTimelineMedia = () => {
    const { saveTimelineMedia } = useTimelineMediasActions();
    return saveTimelineMedia;
};

export const useDeleteTimelineMedia = () => {
    const { deleteTimelineMedia } = useTimelineMediasActions();
    return deleteTimelineMedia;
};
