// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const SubscriptionPlatform = {
  "UNLIMITED_ACCESS": "UNLIMITED_ACCESS",
  "APPLE_STORE": "APPLE_STORE",
  "GOOGLE_PLAY": "GOOGLE_PLAY"
};

const SubscriptionType = {
  "PREMIUM": "PREMIUM",
  "UNLIMITED_MOVES": "UNLIMITED_MOVES"
};

const MoveDiscipline = {
  "POLE": "POLE",
  "AERIAL_HOOP": "AERIAL_HOOP",
  "AERIAL_SILK": "AERIAL_SILK",
  "AERIAL_ROPE": "AERIAL_ROPE",
  "AERIAL_STATIC_TRAPEZE": "AERIAL_STATIC_TRAPEZE",
  "AERIAL_SLING": "AERIAL_SLING",
  "HULA_HOOP": "HULA_HOOP",
  "FLEXIBILITY": "FLEXIBILITY",
  "STRENGTH": "STRENGTH",
  "OTHER": "OTHER"
};

const MoveLevel = {
  "BEGINNER": "BEGINNER",
  "INTERMEDIATE": "INTERMEDIATE",
  "ADVANCED": "ADVANCED"
};

const MoveStatus = {
  "TODO": "TODO",
  "IN_PROGRESS": "IN_PROGRESS",
  "ACHIEVED": "ACHIEVED"
};

const TimelineMediaType = {
  "IMAGE": "IMAGE",
  "VIDEO": "VIDEO"
};

const TimelineItemType = {
  "MOVE": "MOVE",
  "SESSION": "SESSION"
};

const PracticedSideStrength = {
  "STRONG": "STRONG",
  "WEAK": "WEAK",
  "EQUAL": "EQUAL"
};

const SessionDifficulty = {
  "MIXED": "MIXED",
  "BEGINNER": "BEGINNER",
  "INTERMEDIATE": "INTERMEDIATE",
  "ADVANCED": "ADVANCED"
};

const OnlineLibraryUserSubmissionType = {
  "CREATE_MOVE": "CREATE_MOVE",
  "UPDATE_MOVE": "UPDATE_MOVE"
};

const OnlineLibraryUserSubmissionStatus = {
  "WAITING_FOR_REVIEW": "WAITING_FOR_REVIEW",
  "REJECTED": "REJECTED",
  "APPROVED": "APPROVED"
};

const LogVisibility = {
  "PRIVATE": "PRIVATE",
  "FRIENDS": "FRIENDS",
  "PUBLIC": "PUBLIC"
};

const { UserExtraData, UserSubscription, User, UserFollow, AvailableSubscription, MoveCustomLevel, Move, Session, SessionMove, MoveTimeline, TimelineMedia, TimelineGear, TimelineInstructor, OnlineLibraryMove, OnlineLibraryMoveContributor, OnlineLibraryUserSubmission, AppRemoteConfig, MoveTimelineGear, Link, UserMoveTimelineItemsQueryOutput, UserMoveTimelineItemsQueryOutput_MoveTimeline, UserMoveTimelineItemsQueryOutput_TimelineMedia, UserMoveTimelineItemsQueryOutput_Move, UserMoveTimelineItemsQueryOutput_Session } = initSchema(schema);

export {
  UserExtraData,
  UserSubscription,
  User,
  UserFollow,
  AvailableSubscription,
  MoveCustomLevel,
  Move,
  Session,
  SessionMove,
  MoveTimeline,
  TimelineMedia,
  TimelineGear,
  TimelineInstructor,
  OnlineLibraryMove,
  OnlineLibraryMoveContributor,
  OnlineLibraryUserSubmission,
  AppRemoteConfig,
  MoveTimelineGear,
  SubscriptionPlatform,
  SubscriptionType,
  MoveDiscipline,
  MoveLevel,
  MoveStatus,
  TimelineMediaType,
  TimelineItemType,
  PracticedSideStrength,
  SessionDifficulty,
  OnlineLibraryUserSubmissionType,
  OnlineLibraryUserSubmissionStatus,
  LogVisibility,
  Link,
  UserMoveTimelineItemsQueryOutput,
  UserMoveTimelineItemsQueryOutput_MoveTimeline,
  UserMoveTimelineItemsQueryOutput_TimelineMedia,
  UserMoveTimelineItemsQueryOutput_Move,
  UserMoveTimelineItemsQueryOutput_Session
};