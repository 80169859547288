import { useAppRemoteConfigActions } from './app-remote-config';
import { useMeActions } from './me';
import { useMovesActions } from './move';
import { useMoveCustomLevelsActions } from './move-custom-level';
import { useMoveTimelinesActions } from './move-timeline';
import { useTimelineMediasActions } from './move-timeline-media';
import { useTimelineInstructorsActions } from './timeline-instructor';
import { useMoveTimelineGearActions } from './move-timeline-gear';
import { useOnlineLibraryMovesActions } from './online-library-move';
import { useOnlineLibraryUserSubmissionsActions } from './online-library-submission';
import { useSessionsActions } from './session';
import { useUsersActions } from './users';
import { clearCache } from './cache';

export {
    RemoteConfigProperty,
    useAppRemoteConfig,
    useIsAppRemoteConfigInitialized,
    useFetchAppRemoteConfig
} from './app-remote-config';
export {
    useMe,
    useMeExtraData,
    useIdentityId,
    useSubscriptionDetails,
    useFetchMe,
    useFetchSubscriptionDetails,
    useSaveMe,
    useSaveMeExtraData,
    useDeleteMe,
    validateUsername,
    USERNAME_MIN_LENGTH,
    USERNAME_MAX_LENGTH,
    USERNAME_VALIDATION_STATUS
} from './me';
export {
    useMoves,
    useMovesCount,
    useMove,
    useFetchMoves,
    useSaveMove,
    useDeleteMove,
    useDeleteAllMoves
} from './move';
export {
    useMoveCustomLevels,
    useFetchMoveCustomLevels,
    useSaveMoveCustomLevel,
    useDeleteMoveCustomLevel,
    useDeleteAllMoveCustomLevels
} from './move-custom-level';
export {
    useMoveTimelines,
    useFetchMoveTimelines,
    useSaveMoveTimeline,
    useDeleteMoveTimeline
} from './move-timeline';
export { getMoveDisciplines } from './move-discipline';
export { getMoveStatuses } from './move-status';
export { getMoveDifficulties } from './move-difficulty';
export {
    getPracticedSides,
    PracticedSide
} from './practiced-side';
export {
    useTimelineMedias,
    useMoveTimelineUploadedMediasCount,
    useFetchTimelineMedias,
    useSaveTimelineMedia,
    useDeleteTimelineMedia
} from './move-timeline-media';
export {
    useTimelineInstructors,
    useFetchTimelineInstructors,
    useSaveTimelineInstructor,
    useDeleteTimelineInstructor,
    useDeleteAllTimelineInstructors
} from './timeline-instructor';
export {
    useMoveTimelineGear,
    useFetchMoveTimelineGear,
    useSaveMoveTimelineGear,
    useDeleteMoveTimelineGear,
    useDeleteAllMoveTimelineGear
} from './move-timeline-gear';
export {
    useOnlineLibraryMoves,
    useFetchOnlineLibraryMoves,
    useFetchMoreOnlineLibraryMoves,
    useOnlineLibraryMove,
    useFetchOnlineLibraryMove
} from './online-library-move';
export {
    useOnlineLibraryUserSubmissions,
    useFetchSubmissions,
    useSaveSubmission,
    useDeleteSubmission,
    useDeleteAllSubmissions
} from './online-library-submission';
export {
    useSessions,
    useSessionsCount,
    useSession,
    useFetchSessions,
    useSaveSession,
    useDeleteSession,
    useDeleteAllSessions
} from './session';
export {
    useUser,
    useUserIdentityId,
    useFetchUser,
    useUsersSearchData,
    useSearchUsers,
    useSearchMoreUsers,
    useFolloweesData,
    useFollowersData,
    useFetchFollowers,
    useFetchFollowees,
    useFriends,
    useFetchFriends,
    useSaveUserFollow,
    useDeleteUserFollow
} from './users';
export { initCache as initDatastoreCache } from './cache';
export {
    loadData,
    pushData
} from './sync';
export { getOwner } from './user-utils';
export { graphql } from './graphql';

export const useClearDatastore = () => {
    const { resetState: resetMe } = useMeActions();
    const { resetState: resetMoves } = useMovesActions();
    const { resetState: resetMoveCustomLevels } = useMoveCustomLevelsActions();
    const { resetState: resetMoveTimelines } = useMoveTimelinesActions();
    const { resetState: resetTimelineMedias } = useTimelineMediasActions();
    const { resetState: resetTimelineInstructors } = useTimelineInstructorsActions();
    const { resetState: resetMoveTimelineGear } = useMoveTimelineGearActions();
    const { resetState: resetOnlineLibraryMoves } = useOnlineLibraryMovesActions();
    const {
        resetState: resetOnlineLibraryUserSubmissions
    } = useOnlineLibraryUserSubmissionsActions();
    const { resetState: resetSessions } = useSessionsActions();
    const { resetState: resetUsers } = useUsersActions();

    return () => {
        clearCache();
        resetMe();
        resetMoves();
        resetMoveCustomLevels();
        resetMoveTimelines();
        resetTimelineMedias();
        resetTimelineInstructors();
        resetMoveTimelineGear();
        resetOnlineLibraryMoves();
        resetOnlineLibraryUserSubmissions();
        resetSessions();
        resetUsers();
    };
};

export const useGetLinkStores = () => {
    const appRemoteConfig = useAppRemoteConfigActions();
    const me = useMeActions();
    const onlineLibraryMoves = useOnlineLibraryMovesActions();
    const onlineLibraryUserSubmissions = useOnlineLibraryUserSubmissionsActions();
    const moves = useMovesActions();
    const moveCustomLevels = useMoveCustomLevelsActions();
    const moveTimelines = useMoveTimelinesActions();
    const timelineMedias = useTimelineMediasActions();
    const timelineInstructors = useTimelineInstructorsActions();
    const moveTimelineGear = useMoveTimelineGearActions();
    const sessions = useSessionsActions();
    const users = useUsersActions();

    return {
        appRemoteConfig,
        me,
        onlineLibraryMoves,
        onlineLibraryUserSubmissions,
        moves,
        moveCustomLevels,
        moveTimelines,
        timelineMedias,
        timelineInstructors,
        moveTimelineGear,
        sessions,
        users
    };
};
