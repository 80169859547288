import { uploadData } from 'aws-amplify/storage';
import { getCurrentUser } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api';
import { withPromiseTimeout } from '@codexporer.io/promise-timeout';
import { awsConfig } from '../aws.config';

export const StorageAccessLevel = {
    private: 'private',
    protected: 'protected',
    public: 'public'
};

export const StoreType = {
    USER_AVATAR: 'USER_AVATAR',
    USER_COVER_PHOTO: 'USER_COVER_PHOTO',
    MOVE_COVER_PHOTO: 'MOVE_COVER_PHOTO',
    MOVE_COVER_VIDEO: 'MOVE_COVER_VIDEO',
    TIMELINE_PHOTO: 'TIMELINE_PHOTO',
    TIMELINE_VIDEO: 'TIMELINE_VIDEO',
    ONLINE_MOVE_COVER_PHOTO: 'ONLINE_MOVE_COVER_PHOTO',
    ONLINE_MOVE_COVER_VIDEO: 'ONLINE_MOVE_COVER_VIDEO',
    SUBMISSION_MOVE_COVER_PHOTO: 'SUBMISSION_MOVE_COVER_PHOTO',
    SUBMISSION_MOVE_COVER_VIDEO: 'SUBMISSION_MOVE_COVER_VIDEO'
};

const oneHourSeconds = 60 * 60;
const oneDaySeconds = 24 * oneHourSeconds;
const getTimeoutInMillis = 1000 * 60 * 2;
const pushTimeoutInMillis = 1000 * 60 * 10;

const cachedUrls = {};

const getCacheKey = ({
    contentId,
    isThumbnail,
    storeType,
    isPublicAPI
}) => `${contentId}-${isThumbnail ? '-thumbnail-' : ''}${storeType}${isPublicAPI}`;

const canGetFromCache = cache => {
    if (!cache) {
        return false;
    }

    const { expiryDate } = cache;
    const dateNow = new Date();
    const expiryInMs = oneDaySeconds * 1000;

    return dateNow.getTime() - expiryDate.getTime() < expiryInMs;
};

export const getFileUrlById = ({
    id,
    isThumbnail = false,
    storeType,
    isPublicApi = false
}) => withPromiseTimeout(
    (async () => {
        const cacheKey = getCacheKey({
            contentId: id,
            isThumbnail,
            storeType
        });
        const cache = cachedUrls[cacheKey];
        if (canGetFromCache(cache)) {
            return cache.promise;
        }

        cachedUrls[cacheKey] = {
            promise: (async () => {
                const getMediaUrlRestOperation = post({
                    apiName: awsConfig.restApi.name,
                    path: isPublicApi ? '/get-public-media-url' : '/get-private-media-url',
                    options: {
                        body: {
                            contentId: id,
                            storeType,
                            isThumbnail,
                            expires: 2 * oneDaySeconds
                        }
                    }
                });
                const { body } = await getMediaUrlRestOperation.response;
                const { url } = await body.json();
                return url;
            })(),
            expiryDate: new Date()
        };

        return cachedUrls[cacheKey].promise;
    })(),
    { timeoutInMillis: getTimeoutInMillis }
);

export const getMediaThumbnailUrlById = ({
    id,
    storeType,
    isPublicApi = false
}) => getFileUrlById({
    id,
    isThumbnail: true,
    storeType,
    isPublicApi
});

const storeTypeAceessLevelMap = {
    [StoreType.USER_AVATAR]: StorageAccessLevel.protected,
    [StoreType.USER_COVER_PHOTO]: StorageAccessLevel.protected,
    [StoreType.MOVE_COVER_PHOTO]: StorageAccessLevel.private,
    [StoreType.MOVE_COVER_VIDEO]: StorageAccessLevel.private,
    [StoreType.TIMELINE_PHOTO]: StorageAccessLevel.private,
    [StoreType.TIMELINE_VIDEO]: StorageAccessLevel.private,
    [StoreType.ONLINE_MOVE_COVER_PHOTO]: StorageAccessLevel.protected,
    [StoreType.ONLINE_MOVE_COVER_VIDEO]: StorageAccessLevel.protected,
    [StoreType.SUBMISSION_MOVE_COVER_PHOTO]: StorageAccessLevel.private,
    [StoreType.SUBMISSION_MOVE_COVER_VIDEO]: StorageAccessLevel.private
};

const getSaveContentDir = ({
    storeType,
    cognitoUsername
}) => ({
    [StoreType.USER_AVATAR]: 'profile/avatar',
    [StoreType.USER_COVER_PHOTO]: 'profile/cover',
    [StoreType.MOVE_COVER_PHOTO]: `private-content/user/${cognitoUsername}`,
    [StoreType.MOVE_COVER_VIDEO]: 'move/cover-video',
    [StoreType.TIMELINE_PHOTO]: `private-content/user/${cognitoUsername}`,
    [StoreType.TIMELINE_VIDEO]: `private-content/user/${cognitoUsername}`,
    [StoreType.ONLINE_MOVE_COVER_PHOTO]: 'non-user-content/online-library/move',
    [StoreType.ONLINE_MOVE_COVER_VIDEO]: 'non-user-content/online-library/move',
    [StoreType.SUBMISSION_MOVE_COVER_PHOTO]: 'online-library/submission',
    [StoreType.SUBMISSION_MOVE_COVER_VIDEO]: 'online-library/submission'
})[storeType];

const getSaveMediaKey = async ({
    id,
    storeType
}) => {
    let cognitoUsername;
    try {
        cognitoUsername = (await getCurrentUser()).username;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return `${getSaveContentDir({ storeType, cognitoUsername })}/${id}`;
};

export const saveFile = ({
    id,
    mediaId,
    storeType,
    url
}) => withPromiseTimeout(
    (async () => {
        cachedUrls[getCacheKey({ contentId: id, storeType })] = undefined;
        const mediaKey = await getSaveMediaKey({ id: mediaId, storeType });
        const response = await fetch(url);
        const blob = await response.blob();
        const accessLevel = storeTypeAceessLevelMap[storeType];
        await uploadData({
            key: mediaKey,
            data: blob,
            options: {
                accessLevel: accessLevel === 'public' ? 'guest' : accessLevel
            }
        }).result;
    })(),
    { timeoutInMillis: pushTimeoutInMillis }
);
